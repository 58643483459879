
































import { Vue, Component } from 'vue-property-decorator'
import api from '@/utils/api'
import LoginResponse from '@/models/LoginResponse'

@Component
export default class Login extends Vue {

    username = '';
    password = '';
    loginFailed: boolean = false;

    created() {
    }

    async submit() {
        let result = await api.get<LoginResponse>(`login?username=${this.username}&password=${this.password}`);
        if (result.ok) {
            if (result.data?.ErrorMessage == "") {
                localStorage.setItem('CurrentUser', JSON.stringify(result.data));
                this.$router.push('/');
            }
            else {
                localStorage.removeItem('CurrentUser');
                this.loginFailed = true;
            }
        }
        else {
            localStorage.removeItem('CurrentUser');
            this.loginFailed = true;
        }
    }
}
