


















































































import { Vue, Component } from 'vue-property-decorator';
import router from '../router';
import api from '@/utils/api';
import helper from '@/utils/helper';
import LoginResponse from '@/models/LoginResponse';
import SelectOption from '@/models/SelectOption';
import Document from '@/models/Document';


@Component
export default class Documents extends Vue {

    showError = false;
    errorMessage = '';
    loadingData = false;
    login: LoginResponse = { Token: '', UserName: '', LogoDocumentID: 0, ErrorMessage: '', PermDocsGeneral: false, PermDocsInvoices: false, PermDocsSurveys: false };
    siteItems?: { Value: string, DisplayText: string }[] = [];
    selectedSite: string = "0";
    documentIcon: string = "mdi-file-pdf-box";
    documentColour: string = "blue";
    documents?: Document[] = [];
    allDocuments?: Document[] = [];

    folders = [
        { title: 'General', subtitle: '', allowed: false },
        { title: 'Invoices', subtitle: '', allowed: false  },
        { title: 'Surveys', subtitle: '', allowed: false }
    ];

    selectedFolder: string = "";

    mounted() {
    };

    async created() {
        this.login = JSON.parse(localStorage.getItem('CurrentUser')!);
        this.loadingData = true;
        api.init(this);
        await this.getSites();
        await this.getDocuments();
        this.loadingData = false;
        this.folderSelected(this.selectedFolder);
        this.folders[0].subtitle = this.displayFolderSubtitle('general');
        this.folders[0].allowed = this.login.PermDocsGeneral;
        this.folders[1].subtitle = this.displayFolderSubtitle('invoice');
        this.folders[1].allowed = this.login.PermDocsInvoices;
        this.folders[2].subtitle = this.displayFolderSubtitle('survey');
        this.folders[2].allowed = this.login.PermDocsSurveys;
    }

    async siteChanged(site: string) {
        this.selectedSite = site;
        localStorage.setItem('SelectedSiteID', site);
        this.loadingData = true;
        await this.getDocuments();
        this.loadingData = false;
        this.folders[0].subtitle = this.displayFolderSubtitle('general');
        this.folders[1].subtitle = this.displayFolderSubtitle('invoice');
        this.folders[2].subtitle = this.displayFolderSubtitle('survey');
        this.folderSelected(this.selectedFolder);
    }

    async getSites() {
        let sitesResponse = await api.get<SelectOption[]>('sites?accessToken=' + this.login.Token);

        if (sitesResponse.ok) {
            this.siteItems = sitesResponse.data!;

            if (this.siteItems && (this.siteItems.length > 0)) {
                this.selectedSite = helper.getSelectedSite(this.siteItems[0].Value);
            }
        }
        else {
            this.showError = true;
            this.errorMessage = sitesResponse.error!;
        }
    }

    async getDocuments() {
        if (this.selectedSite) {
            let documentsResponse = await api.get<Document[]>('documents/site?accessToken=' + this.login.Token + '&siteID=' + this.selectedSite);

            if (documentsResponse.ok) {
                this.allDocuments = documentsResponse.data!;
            }
            else {
                this.showError = true;
                this.errorMessage = documentsResponse.error!;
            }
        }
    }

    goBack() {
        router.back();
    }

    folderClicked() {
        alert('Hello');
    }

    folderSelected(folder: any) {
        if (folder === 'Surveys' && this.login.PermDocsSurveys) {
            this.documents = this.allDocuments?.filter(doc => doc.DocumentType === 'survey');
            this.documentIcon = 'mdi-file-pdf-box';
            this.documentColour = "blue";
        };

        if (folder === 'Invoices' && this.login.PermDocsInvoices) {
            this.documents = this.allDocuments?.filter(doc => doc.DocumentType === 'invoice');
            this.documentIcon = 'mdi-currency-gbp';
            this.documentColour = "green";
        };

        if (folder === 'General' && this.login.PermDocsGeneral) {
            this.documents = this.allDocuments?.filter(doc => doc.DocumentType === 'general');
            this.documentIcon = 'mdi-text-box-outline';
            this.documentColour = "grey";
        };
    }

    async downloadDocument(documentID: number) {
        let url = api.baseUrl + "document/download?accessToken=" + this.login.Token + "&documentID=" + documentID.toString()
        window.open(url, "_blank");
    }

    displaySubtitle(date: Date) : string {
        return 'Last modified: ' + helper.displayDateTime(date);
    }

    displayFolderSubtitle(documentType: string): string {
        if (documentType == 'general' && !this.login.PermDocsGeneral) {
            return "Access denied"
        }
        if (documentType == 'invoice' && !this.login.PermDocsInvoices) {
            return "Access denied"
        }
        if (documentType == 'survey' && !this.login.PermDocsSurveys) {
            return "Access denied"
        }

        var filtered = this.allDocuments?.filter(doc => doc.DocumentType === documentType);
        if (filtered && filtered.length > 0) {
            var millisecondArray = filtered!.map(function (d) {
                var tempDate = new Date(d.LastModifiedDate);
                return tempDate.getTime();
            });
            var maxMillisecond = Math.max.apply(null, millisecondArray);
            var maxDate = new Date(maxMillisecond);
            return 'Last updated: ' + helper.displayDateTime(maxDate);
        }
        else {
            return 'No files.';
        }
    }

    getLogoURL(): string {
        return helper.getLogoURL(this.login.Token, this.login.LogoDocumentID);
    }
}
