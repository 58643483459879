








































import { Vue, Component } from 'vue-property-decorator';
import { Chart, ChartItem, ChartConfiguration, registerables } from 'chart.js';
import api from '@/utils/api'
import helper from '@/utils/helper'
import LoginResponse from '@/models/LoginResponse';
import SelectOption from '@/models/SelectOption';
import SurveyDetail from '@/models/SurveyDetail';



@Component({
    name: 'Home'
})
export default class Home extends Vue {

    showError: boolean = false;
    errorMessage: string = '';
    loadingData: boolean = false;
    login: LoginResponse = { Token: '', UserName: '', LogoDocumentID: 0, ErrorMessage: '', PermDocsGeneral: false, PermDocsInvoices: false, PermDocsSurveys: false };
    selectedSite: string = "0";
    latestSurveyText: string = "";
    summaryChart?: Chart;
    remediatedChart?: Chart;
    replacedChart?: Chart;
    siteItems?: SelectOption[] = [];
    surveys: SurveyDetail[] = [];

    test() {
        // var canvas = this.$refs.chart1! as HTMLCanvasElement;
        // var ctx = canvas.getContext('2d')!;
        var ctx = this.summaryChart!.ctx!;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.font = "20px Arial";
        ctx.fillStyle = '#000';
        ctx.fillText('LogoURL='+this.login.LogoDocumentID, 100, 100);
    }
        

    beforeDraw (chart : any) {
        if (chart.config.options.elements.center) {
            // Get ctx from string
            var ctx = chart.ctx;

            // Get options from the center object in options
            var centerConfig = chart.config.options.elements.center;
            var fontStyle = centerConfig.fontStyle || 'Arial';
            var txt = centerConfig.text;
            var color = centerConfig.color || '#000';
            var maxFontSize = centerConfig.maxFontSize || 75;
            var sidePadding = centerConfig.sidePadding || 20;
            const innerRadius = chart._metasets[chart._metasets.length - 1].data[0].innerRadius;
            var sidePaddingCalculated = (sidePadding / 100) * (innerRadius * 2)
            // Start with a base font of 30px
            ctx.font = "30px " + fontStyle;

            // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
            var stringWidth = ctx.measureText(txt).width;
            var elementWidth = (innerRadius * 2) - sidePaddingCalculated;

            // Find out how much the font can grow in width.
            var widthRatio = elementWidth / stringWidth;
            var newFontSize = Math.floor(30 * widthRatio);
            var elementHeight = (innerRadius * 2);

            // Pick a new font size so it will not be larger than the height of label.
            var fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize);
            var minFontSize = centerConfig.minFontSize;
            var lineHeight = centerConfig.lineHeight || 25;
            var wrapText = false;

            if (minFontSize === undefined) {
                minFontSize = 20;
            }

            if (minFontSize && fontSizeToUse < minFontSize) {
                fontSizeToUse = minFontSize;
                wrapText = true;
            }

            // Set font settings to draw it correctly.
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            var centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
            var centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
            ctx.font = fontSizeToUse + "px " + fontStyle;
            ctx.fillStyle = color;

            if (!wrapText) {
                ctx.fillText(txt, centerX, centerY);
                return;
            }

            var words = txt.split(' ');
            var line = '';
            var lines = [];

            // Break words up into multiple lines if necessary
            for (var n = 0; n < words.length; n++) {
                var testLine = line + words[n] + ' ';
                var metrics = ctx.measureText(testLine);
                var testWidth = metrics.width;
                if (testWidth > elementWidth && n > 0) {
                    lines.push(line);
                    line = words[n] + ' ';
                } else {
                    line = testLine;
                }
            }

            // Move the center up depending on line height and number of lines
            centerY -= (lines.length / 2) * lineHeight;

            for (var n = 0; n < lines.length; n++) {
                ctx.fillText(lines[n], centerX, centerY);
                centerY += lineHeight;
            }
            //Draw text in center
            ctx.fillText(line, centerX, centerY);
        }
    };

    mounted() {
        Chart.register(...registerables);

        (Chart as any).register([{
            id: 'p1',
            beforeDraw: this.beforeDraw
        }]);

        this.summaryChart = new Chart(
            this.$refs.chart1 as ChartItem,
            {
                type: 'doughnut',
                data: {
                    labels: [
                        'OK',
                        'Remediation',
                        'Replace'
                    ],
                    datasets: [{
                        data: [0, 0, 0],
                        backgroundColor: [
                            'rgb(60, 190, 97)',
                            'rgb(54, 162, 235)',
                            'rgb(255, 205, 86)'
                        ],
                        hoverOffset: 4,
                        label: 'My Dataset'
                    }]
                },
                options: {
                    plugins: {
                        title: {
                            display: true,
                            text: 'Fire Door Summary',
                            padding: {
                                top: 10,
                                bottom: 10
                            },
                            font: { size: 16 }
                        }
                    },
                    elements: {
                        center: {
                            text: '',
                            color: '#555555', // Default is #000000
                            fontStyle: 'Arial', // Default is Arial
                           // sidePadding: 20, // Default is 20 (as a percentage)
                            minFontSize: 10, // Default is 20 (in px), set to false and text will not wrap.
                            maxFontSize: 40 // Default is 20 (in px), set to false and text will not wrap.
                           // lineHeight: 25 // Default is 25 (in px), used for when text wraps
                        }
                    } as any
                }
            }
        );

        this.remediatedChart = new Chart(
            this.$refs.chart2 as ChartItem,
            {
                type: 'doughnut',
                data: {
                    labels: [
                        'Complete',
                        'Outstanding'
                    ],
                    datasets: [{
                        data: [0, 0],
                        backgroundColor: [
                            'rgb(60, 190, 97)',
                            'rgb(255, 205, 86)'
                        ],
                        hoverOffset: 4
                    }]
                },
                options: {
                    plugins: {
                        title: {
                            display: true,
                            text: 'Doors to be Remediated',
                            padding: {
                                top: 10,
                                bottom: 10
                            },
                            font: { size: 16 }
                        }
                    },
                    elements: {
                        center: {
                            text: '',
                            color: '#555555',
                            maxFontSize: 40
                        }
                    } as any

                }
            }
        );

        this.replacedChart = new Chart(
            this.$refs.chart3 as ChartItem,
            {
                type: 'doughnut',
                data: {
                    labels: [
                        'Complete',
                        'Outstanding'
                    ],
                    datasets: [{
                        data: [0, 0],
                        backgroundColor: [
                            'rgb(60, 190, 97)',
                            'rgb(255, 205, 86)'
                        ],
                        hoverOffset: 4
                    }]
                },
                options: {
                    plugins: {
                        title: {
                            display: true,
                            text: 'Doors to be Replaced',
                            padding: {
                                top: 10,
                                bottom: 10
                            },
                            font: { size: 16 }
                        }
                    },
                    elements: {
                        center: {
                            text: '',
                            color: '#555555',
                            maxFontSize: 40
                        }
                    } as any

                }
            }
        );
    }

    async created() {
        this.login = JSON.parse(localStorage.getItem('CurrentUser')!);
        this.loadingData = true;
        api.init(this);
        await this.getSites();
        await this.getSurveys();
        this.loadingData = false;
        this.setSurveyData();
    };

    async getSites() {
        let siteResponse = await api.get<SelectOption[]>('sites?accessToken=' + this.login.Token);

        if (siteResponse.ok) {
            this.siteItems = siteResponse.data!;

            if (this.siteItems && (this.siteItems.length > 0)) {
                this.selectedSite = helper.getSelectedSite(this.siteItems[0].Value);
            }
        }
        else {
            this.showError = true;
            this.errorMessage = siteResponse.error!;
        }
    }

    async getSurveys() {
        if (this.selectedSite) {
            let surveysResponse = await api.get<SurveyDetail[]>('surveysdetail?accessToken=' + this.login.Token + '&siteID=' + this.selectedSite);

            if (surveysResponse.ok) {
                this.surveys = surveysResponse.data!;
            }
            else {
                this.showError = true;
                this.errorMessage = surveysResponse.error!;
            }
        }
    }

    async siteChanged(site : string) {
        this.selectedSite = site;
        localStorage.setItem('SelectedSiteID', site);

        this.loadingData = true;
        await this.getSurveys();
        this.loadingData = false;

        this.setSurveyData();
    }

    setSurveyData() {
        let firedoorSurveys = this.surveys.filter(s => s.SurveyType == 'Fire Door');

        if (firedoorSurveys && firedoorSurveys.length > 0) {
            let latest: SurveyDetail = firedoorSurveys[0];
            this.updateCharts(latest.AcceptableCount, latest.RemediationCount, latest.ReplaceCount, latest.RemediatedCount, latest.ReplacedCount);
            this.latestSurveyText = 'Latest fire door survey :  ' + latest.Status + ' - ' + helper.displayDateTime(latest.AppointmentDate);
        }
        else {
            this.updateCharts(0, 0, 0, 0, 0);
            this.latestSurveyText = 'No fire door surveys for this site';
        }
    }

    updateCharts(pAcceptable: number, pRemediation: number, pReplace: number, pRemediated: number, pReplaced: number) {
        // Sumary Chart data 0=OK, 1=Remediation, 2=Replace
        this.summaryChart!.data.datasets[0].data = [pAcceptable, pRemediation, pReplace];
        const totalDoors: number = pAcceptable + pRemediation + pReplace;
        if (totalDoors > 0) {
            ((this.summaryChart!.options.elements) as any).center.text = "Total: " + totalDoors.toString();
        }
        else {
            ((this.summaryChart!.options.elements) as any).center.text = "No Data";
        }
        this.summaryChart!.update();

        // Remediation Chart 0=Complete, 1=Outstanding
        let remediationRequired = pRemediation - pRemediated;
        this.remediatedChart!.data.datasets[0].data = [pRemediated, remediationRequired];
        if (pRemediation > 0) {
            ((this.remediatedChart!.options.elements) as any).center.text = "Total: " + pRemediation.toString();
        }
        else {
            ((this.remediatedChart!.options.elements) as any).center.text = "No Data";
        }
        this.remediatedChart!.update();

        // Replaced Chart 0=Complete, 1=Outstanding
        let replaceRequired = pReplace - pReplaced;
        this.replacedChart!.data.datasets[0].data = [pReplaced, replaceRequired];
        if (pReplace > 0) {
            ((this.replacedChart!.options.elements) as any).center.text = "Total: " + pReplace.toString();
        }
        else {
            ((this.replacedChart!.options.elements) as any).center.text = "No Data";
        }
        this.replacedChart!.update();
    }

    getLogoURL(): string {
        return helper.getLogoURL(this.login.Token, this.login.LogoDocumentID);
    }
}

