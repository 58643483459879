import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Survey from '../views/Survey.vue'
import Surveys from '../views/Surveys.vue'
import Documents from '../views/Documents.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    { path: '/', name: 'Home', component: Home },
    { path: '/login', name: 'Login', component: Login },
    { path: '/surveys', name: 'Surveys', component: Surveys },
    { path: '/survey/:id', name: 'Survey', component: Survey, props: route => ({ id: parseInt(route.params.id) }) },
    { path: '/documents', name: 'Documents', component: Documents },
    {
        path: '/about', name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    }
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes
})

router.beforeEach((to, from, next) => {
    if (to.name !== 'Login' && !localStorage.getItem('CurrentUser')) {
        next('/login');
    }
    else next();
})

export default router
