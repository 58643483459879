


















































































import { Vue, Component, Prop } from 'vue-property-decorator'
import router from '../router';
import api from '@/utils/api';
import helper from '@/utils/helper';
import LoginResponse from '@/models/LoginResponse'
import SurveyDetail from '@/models/SurveyDetail';


@Component
export default class Survey extends Vue {

    @Prop(Number) id!: number;
    showError = false;
    errorMessage = '';
    login: LoginResponse = { Token: '', UserName: '', LogoDocumentID: 0, ErrorMessage: '', PermDocsGeneral: false, PermDocsInvoices: false, PermDocsSurveys: false };
    selectedSiteID: string = '';
    survey: SurveyDetail = { ID: 0, SiteID: 0, AppointmentDate: new Date(), AccountCode: '', SiteName: '', SolarvistaJobID: 0, SurveyTypeID: 0, SurveyType: '', StatusID: 0, Status: '', Engineer: '', InitialSurveyComplete: false, RiskCount: 0, RemediationCount: 0, AcceptableCount: 0, NoAccessCount: 0, ReplaceCount: 0, RemediatedCount: 0, ReplacedCount: 0 };
    surveyDocuments: Document[] = [];

    async created() {
        this.login = JSON.parse(localStorage.getItem('CurrentUser')!);
        this.selectedSiteID = localStorage.getItem('SelectedSiteID')!;

        api.init(this);
        await this.getSurvey();
        await this.getDocuments();
    }

    async getSurvey() {
        let surveyResponse = await api.get<SurveyDetail>('survey?accessToken=' + this.login.Token + '&surveyID=' + this.id);

        if (surveyResponse.ok) {
            this.survey = surveyResponse.data!;
        }
        else {
            this.showError = true;
            this.errorMessage = surveyResponse.error!;
        }
    }

    async getDocuments() {
        if (this.login.PermDocsSurveys) {
            let documentsResponse = await api.get<Document[]>('documents/survey?accessToken=' + this.login.Token + '&surveyID=' + this.id);

            if (documentsResponse.ok) {
                this.surveyDocuments = documentsResponse.data!;
            }
            else {
                this.showError = true;
                this.errorMessage = documentsResponse.error!;
            }
        }
    }

    async downloadDocument(documentID: number) {
        let url = api.baseUrl + "document/download?accessToken=" + this.login.Token + "&documentID=" + documentID.toString()
        window.open(url, "_blank");
    }

    goBack() {
        router.back();
    }

    getDocumentIcon(surveyType: string): string {
        return 'mdi-file-pdf-box';
        if (surveyType == 'Fire Door') {
            return 'mdi-door';
        }
        else {
            return 'mdi-alert-octagon-outline';
        }
    }

    getDocumentColour(surveyType: string): string {
        return 'blue';
        if (surveyType == 'Fire Door') {
            return 'red';
        }
        else {
            return 'red darken-3';
        }
    }

    displaySubtitle(date: Date): string {
        return 'Last modified: ' + helper.displayDateTime(date);
    }

    displayDate(date: Date | string | null | undefined) : string {
        return helper.displayDateTime(date)
    }
}
