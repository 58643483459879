

























































import { Vue, Component } from 'vue-property-decorator'
import router from '../router';
import api from '@/utils/api';
import helper from '@/utils/helper';
import LoginResponse from '@/models/LoginResponse';
import SelectOption from '@/models/SelectOption';
import Survey from '@/models/Survey';


@Component
export default class Surveys extends Vue {

    showError = false;
    errorMessage = '';
    loadingData = false;
    login: LoginResponse = { Token: '', UserName: '', LogoDocumentID: 0, ErrorMessage: '', PermDocsGeneral: false, PermDocsInvoices: false, PermDocsSurveys: false };
    siteItems?: { Value: string, DisplayText: string }[] = [];
    selectedSite: string = "0";
    surveys: Survey[] = [];


    async created() {
        this.login = JSON.parse(localStorage.getItem('CurrentUser')!);
        this.loadingData = true;
        api.init(this);
        await this.getSites();
        await this.getSurveys();
        this.loadingData = false;
    }

    async siteChanged(site: string) {
        this.selectedSite = site;
        localStorage.setItem('SelectedSiteID', site);
        this.loadingData = true;
        await this.getSurveys();
        this.loadingData = false;
    }

    async getSites() {
        let sitesResponse = await api.get<SelectOption[]>('sites?accessToken=' + this.login.Token);

        if (sitesResponse.ok) {
            this.siteItems = sitesResponse.data!;

            if (this.siteItems && (this.siteItems.length > 0)) {
                this.selectedSite = helper.getSelectedSite(this.siteItems[0].Value);
            }
        }
        else {
            this.showError = true;
            this.errorMessage = sitesResponse.error!;
        }
    }

    async getSurveys() {
        if (this.selectedSite) {
            let surveysResponse = await api.get<Survey[]>('surveys?accessToken=' + this.login.Token + '&siteID=' + this.selectedSite);

            if (surveysResponse.ok) {
                this.surveys = surveysResponse.data!;
            }
            else {
                this.showError = true;
                this.errorMessage = surveysResponse.error!;
            }
        }
    }

    goBack() {
        router.back();
    }

    getSurveyIcon(surveyType: string): string {
        if (surveyType == 'Fire Door') {
            return 'mdi-door';
        }
        else {
            return 'mdi-alert-octagon-outline';
        }
    }

    getSurveyColour(surveyType: string): string {
        if (surveyType == 'Fire Door') {
            return 'red';
        }
        else {
            return 'red darken-3';
        }
    }

    displayDate(date: Date) {
        return helper.displayDate(date);
    }

    getLogoURL(): string {
        return helper.getLogoURL(this.login.Token, this.login.LogoDocumentID);
    }

}
