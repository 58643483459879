import Vue from 'vue';
import api from '@/utils/api'

export default {

    displayDate(date: Date | string | null | undefined) {
        if (!date) return '';
        date = new Date(date);
        const mth = date.toLocaleDateString('default', { month: 'short' });
        return `${date.getDate().toString().padStart(2, '0')} ${mth} ${date.getFullYear()}`;
    },

    displayDateTime(date: Date | string | null | undefined) {
        if (!date) return '';
        date = new Date(date);
        const mth = date.toLocaleDateString('default', { month: 'short' });
        return `${date.getDate().toString().padStart(2, '0')} ${mth} ${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
    },

    getSelectedSite(pDefaultSite: string) : string {
        let site = localStorage.getItem('SelectedSiteID');
        if (site && site != '') {
            return site;
        }
        else {
            localStorage.setItem('SelectedSiteID', pDefaultSite);
            return pDefaultSite;
        }
    },

    getLogoURL(pToken: string, pDocumentID: number): string {
        if (pDocumentID != 0) {
            return api.baseUrl + "document/download?accessToken=" + pToken + "&documentID=" + pDocumentID.toString();
        }
        else {
            return "";
        }
    }
}