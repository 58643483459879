import Vue from 'vue';

export interface ApiResponse<T> {
    ok: boolean;
    error: string | undefined;
    status: number;
    statusText: string;
    data: T | null;
}

export default {
    // Must have trailing '/'
    baseUrl: process.env.VUE_APP_API_BASE_URL,
    // Must match equivalent error message on server side.
    INVALID_USER_TOKEN : "Invalid user token.",
    vm: new Vue(),

    /**
     * Pass in the main Vue instance to use the router if api calls return 401
     * @param {Vue} vm - Vue instance
     */
    init(vm: Vue) {
        this.vm = vm;
    },

    /**
     * 
     * @param {string} path
     * @param {any} options
     */
    async fetch2(path: string, options?: RequestInit) {
        const result = await fetch(path, options);
        if (result.status === 401) {
            this.vm.$router.push('/login');
            return new Response('null');
        }
        return result;
    },

    async get<T = any>(path: string): Promise<ApiResponse<T>> {
        try {
            const result = await fetch(this.baseUrl + path);

            //const resultText = await result.text();
            var parsedData;

            if (result.ok) {
                //console.log('Result: ' + resultText);
                parsedData = await result.json();
            }

            const r: ApiResponse<T> = {
                ok: result.ok && !parsedData.Error,
                error: result.ok ? parsedData.Error : result.statusText,
                status: result.status,
                statusText: result.statusText,
                data: (result.ok && !parsedData.Error) ? parsedData : null
            };

            if (r.error === this.INVALID_USER_TOKEN) {
                this.vm.$router.push('/login');
            }
            return r;
        }
        catch (err) {
            const r: ApiResponse<T> = {
                ok: false,
                error: err.message,
                status: 999,
                statusText: err.message,
                data: null
            };

            return r;
        }
    },

    async post<T = any>(path: string, data: any): Promise<ApiResponse<T>> {
        try {
            const result = await fetch(this.baseUrl + path, {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            //const resultText = await result.text();
            var parsedData;

            if (result.ok) {
                //console.log('Result: ' + resultText);
                parsedData = await result.json();
            }

            const r: ApiResponse<T> = {
                ok: result.ok && !parsedData.Error,
                error: result.ok ? parsedData.Error : result.statusText,
                status: result.status,
                statusText: result.statusText,
                data: (result.ok && !parsedData.Error) ? parsedData : null
            };

            return r;
        }
        catch (err) {
            const r: ApiResponse<T> = {
                ok: false,
                error: err.message,
                status: 999,
                statusText: err.message,
                data: null
            };

            return r;
        }
    },

    async put<T = any>(path: string, data: any) {
        const result = await this.fetch2(this.baseUrl + path, {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return result.json() as Promise<T>;
    },

    async delete(path: string) {
        await this.fetch2(this.baseUrl + path, {
            method: 'DELETE'
        });
    },

    async postWithFiles<T = any>(path: string, data: any) {
        const result = await this.fetch2(this.baseUrl + path, {
            method: 'POST',
            body: data
        });

        return result.json() as Promise<T>;
    }
}