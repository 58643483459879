





































































































import { Vue, Component } from 'vue-property-decorator'

@Component
export default class App extends Vue {
    drawer: boolean = false;
    showError: boolean = false;
    errorMessage: string = '';

    logout() {
        localStorage.removeItem('CurrentUser');
        let path = "/login";
        if (this.$route.path !== path) this.$router.push(path)
    }

    navHome() {
        let path = "/";
        if (this.$route.path !== path) this.$router.push(path)
    }

    navDocuments() {
        let path = "/Documents";
        if (this.$route.path !== path) this.$router.push(path)
    }

    navSurveys() {
        let path = "/Surveys";
        if (this.$route.path !== path) this.$router.push(path)
    }

    navAccount() {
        let path = "/Account";
        if (this.$route.path !== path) this.$router.push(path)
    }
}

